<template>
	<div class="container">
		<LoginWrap v-if="showLogin"></LoginWrap>
		<div class="zhanwei20"></div>
		<div class="flex-row align-center space-between creator-sec">
			<div class="flex-row align-center">
				<img v-if="info.userPic" src="../assets/mine/head_icon.png" class="creator-head" />
				<img v-else src="../assets/mine/head_icon.png" class="creator-head" />
				<div class="flex-column justify-center creator-name-sec">
					<div class="flex-row align-center">
						<div class="creator-user-name">{{info.userNickName}}</div>
						<div v-if="info.userId== 163598" class="gf">官方</div>
						<div v-if="info.userId== 163666  || info.userId== 163690" class="sgf">项目管理员</div>
					</div>
					<div class="creator-time">{{info.createTime}}</div>
				</div>
			</div>
			<template v-if="islogin">
				<div class="attention-btn" v-if="isFollow == 0" @click.stop="addFollow">关注</div>
				<div class="attention-btn-false" v-else @click.stop="delFollow">已关注</div>
			</template>
		</div>
		<div class="detail-sec">
			<div class="detail-title">{{info.title}}</div>
			<div class="progress-sec">
				<div class="flex-row align-center space-between progress-item">
					<div class="flex-row align-center">
						<div class="progress-text">目前</div>
						<div class="progress-line">
							<div class="progress-line-finish" :style="'width:'+info.currentSupportRate+'%;'"></div>
						</div>
					</div>
					<div class="progress-num">{{info.currentSupportRate}}%</div>
				</div>
				<div class="flex-row align-center space-between progress-item">
					<div class="flex-row align-center">
						<div class="progress-text">目标</div>
						<div class="progress-line-active"></div>
					</div>
					<div class="progress-num">{{info.targetSupportRate}}%</div>
				</div>
				<div class="flex-row align-center space-between relative deadline-sec"
					:class="false?'':'deadline-border-grey'">
					<div class="flex-row align-center">
						<!-- 	<div class="deadline-text">剩余时间：</div>
						<div class="deadline-date">{{info.formatDate}}天</div> -->
						<div class="deadline-text2">截止时间：</div>
						<div class="deadline-date2">{{info.endDate}}</div>
					</div>
					<img src="../assets/support_tian.png" class="support-btn" @click="supportProposal()"
						v-if="!isSupport" />
					<div class="support-btn-grey" v-else>我已支持</div>
				</div>
			</div>
			<div class="detail-desc" v-html="info.content"></div>
		</div>
		<!-- 评论列表开始 -->
		<div class="review-sec">
			<div class="review-all-num">{{total}}条网友热评</div>
			<van-list v-model="loading" :immediate-check="immediateCheck" @load="getCommentListByTargetId"
				:finished="finished" finished-text="没有更多了" :error.sync="error" error-text="请求失败，点击重新加载">
				<div class="review-item-sec" v-for="(item,index) in list" :key="index">
					<div class="flex-row align-top">
						<img v-if="item.userPic" :src="item.userPic" class="review-item-head" />
						<img v-else src="../assets/mine/head_icon.png" class="review-item-head" alt="">
						<div class="flex-column">
							<div class="flex-row align-center">
								<div class="review-item-user">{{item.userName}}</div>
								<div class="author-icon" v-if="item.userId == info.userId">作者</div>
								<div v-if="item.userId== 163666  || item.userId== 163690" class="sgf2">项目管理员</div>
							</div>
							<div class="review-item-con">{{item.content}}</div>
							<div class="flex-row align-center space-between">
								<div class="flex-row align-center">
									<div class="review-item-date">{{item.createTime}}</div>
									<div class="response-btn" @click="responseReview(index,2)">回复</div>
									<div class="author-praise-status" v-if="item.authorLike == 1">作者赞过</div>
								</div>
								<div class="flex-row align-center" @click="praiseReview(index,-1,1)">
									<img src="../assets/praise_icon_select.png" class="review-item-praise-icon"
										v-if="item.isLike==1" />
									<img src="../assets/praise_icon.png" class="review-item-praise-icon" v-else />
									<div class="review-item-praise-num" v-if="item.likeTotal >0">
										{{item.formatLikeTotal}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="response-list">
						<template v-for="(itm,idx) in item.replyPageInfo.list">
							<div class="flex-row align-top response-item" :key="idx">
								<img v-if="itm.userPic" :src="itm.userPic" class="review-item-head2" />
								<img v-else src="../assets/mine/head_icon.png" class="review-item-head2" />
								<div class="flex-column">
									<div class="flex-row align-center">
										<div class="review-item-user">{{itm.userName}}</div>
										<div class="author-icon" v-if="itm.userId == info.userId">作者</div>
										<div v-if="itm.userId== 163666  || itm.userId== 163690" class="sgf2">项目管理员</div>
										<template v-if="itm.replyUserId">
											<img src="../assets/response_to_icon.png" class="review-item-to-icon" />
											<div class="review-item-user">{{itm.replyUserName}}</div>
											<div class="author-icon" v-if="itm.replyUserId == info.userId">作者</div>
											<div v-if="itm.replyUserId== 163666  || itm.replyUserId== 163690" class="sgf2">项目管理员</div>
										</template>
									</div>
									<div class="review-item-con2">{{itm.content}}</div>
									<div class="flex-row align-center space-between">
										<div class="flex-row align-center">
											<div class="review-item-date">{{itm.createTime}}</div>
											<div class="response-btn" @click="responseReview(index,3,idx)">回复
											</div>
											<div class="author-praise-status" v-if="itm.authorLike == 1">作者赞过</div>
										</div>
										<div class="flex-row align-center" @click="praiseReview(index,idx,2)">
											<img src="../assets/praise_icon_select.png" class="review-item-praise-icon"
												v-if="itm.isLike==1" />
											<img src="../assets/praise_icon.png" class="review-item-praise-icon"
												v-else />
											<div class="review-item-praise-num" v-if="itm.likeTotal >0">
												{{itm.formatLikeTotal}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>
					<div class="xian"></div>
					<div class="flex-row align-center justify-center zhankai-sec"
						v-if='item.replyPageInfo.total > item.replyPageInfo.list.length'
						@click="zhankaiResponse(index)">
						<!-- <template v-if='item.is_zhankai == 0'> -->
						<div class="zhankai-text">展开更多回复</div>
						<img src="../assets/zhankai.png" class="zhankai-icon" />
						<!-- </template> -->
						<!-- <template v-else>
							<div class="zhankai-text">收起</div>
							<img src="../assets/zhankai.png" class="shouqi-icon" />
						</template> -->
					</div>
				</div>
			</van-list>
		</div>
		<!-- 评论列表结束 -->
		<div class="height120"></div>
		<div class="review-input-sec">
			<div class="review-input-btn" @click="showReviewInput()">文明发言</div>
		</div>
		<div class="review-input-modal-sec align-end justify-center flex-row"
			:style="'bottom:'+ (showInput== 0?'-9999px':'0')">
			<textarea ref="mytextarea" @keypress="checkMsg" :placeholder="placeholder" class="review-input-modal-area"
				@keyup.enter='send()' enterkeyhint="send" v-model="msg"></textarea>
			<div class="send-btn" @click="send()">发送</div>
		</div>
		<!-- 提案结果弹窗开始 -->
		<div class="fail-modal-mask" v-if="modelStatus == 2">
			<div class="fail-modal flex-column align-center">
				<img src="../assets/fail_tishi_icon.png" class="fail-tishi-icon" />
				<div class="toupiao-tishi-title">提案支持提示</div>
				<div class="toupiao-tishi-con">验证未通过，没有相关NFT。</div>
				<div class="flex-row align-center toupiao-fail-btn-sec">
					<div class="toupiao-fail-btn" @click="abandon()">放弃支持</div>
					<div class="toupiao-fail-btn2" @click="goDeal()">去交易市场</div>
				</div>
			</div>
		</div>
		<div class="fail-modal-mask" v-if="modelStatus == 1">
			<div class="fail-modal flex-column align-center">
				<img src="../assets/tian_succ_tishi.png" class="fail-tishi-icon" />
				<div class="toupiao-tishi-title">提案支持提示</div>
				<div class="toupiao-tishi-con2">验证通过，已支持！</div>
				<div class="toupiao-succ-btn" @click="abandon()">确认</div>
			</div>
		</div>
		<!-- 提案结果弹窗结束 -->
	</div>
</template>

<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	import LoginWrap from '@/components/Login.vue';
	Vue.use(Vant);
	export default {
		data() {
			return {
				id: '',
				projectId: '',
				info: {},
				loading: false,
				error: false,
				finished: false,
				pageNum: 1,
				pageSize: 10,
				isFollow: 1,

				showInput: 0,
				msg: '',
				msgType: 1, //1.发表评论,2 回复评论 3回复回复
				subId: '',
				placeholder: '',
				subindex: '', //回复一级下标
				ssubindex: '', //回复二级下标

				total: '',
				list: [],
				immediateCheck: true,
				isTian: false, //提案也写在这里了，如果不合适可以直接再复制一份
				showLogin: false,
				isSupport: 0,
				modelStatus: 0,
				projectDetail: {}
			}
		},
		computed: {
			islogin() {
				return this.$store.state.user.token ? true : false
			},
		},
		components: {
			LoginWrap,
		},
		methods: {
			goDeal() {
				window.location.href = 'https://shop.metacbc.cn'
			},
			abandon() {
				this.modelStatus = 0;
			},
			login() {
				this.showLogin = true;
				this.$forceUpdate()
			},
			closeLogin() {
				this.showLogin = false;
				if (this.$store.state.user.token) {
					this.checkIsFollow();
					this.addReadQuantity();
					this.checkIsSupportProposal();
				}
			},
			supportProposal() {
				const that = this;
				if (!that.$store.state.user.token) {
					that.login();
					return;
				}
				let params = new FormData()
				params.append('proposalId', that.id)
				that.axios.post(config.requestUrl + '/front/forum/supportProposal', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.modelStatus = 1
						that.isSupport = 1;
						that.getTopicDetails();
					} else if (response.data.code == -101) {
						that.modelStatus = 2;
					} else {
						that.$toast(response.data.msg)
					}
				})
			},
			//是否支持过
			checkIsSupportProposal() {
				const that = this;
				let params = new FormData()
				params.append('proposalId', that.id)
				that.axios.post(config.requestUrl + '/front/forum/checkIsSupportProposal', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.isSupport = 0;
					} else {
						that.isSupport = 1;
					}
				})
			},
			//阅读数量+1
			addReadQuantity() {
				const that = this;
				let params = new FormData()
				params.append('targetId', that.id)
				params.append('targetType', 2)
				that.axios.post(config.requestUrl + '/front/forum/addReadQuantity', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					let a = 1;
				})
			},
			//是否关注过作者
			checkIsFollow() {
				const that = this;
				let params = new FormData()
				params.append('targetUserId', that.info.userId)
				that.axios.post(config.requestUrl + '/front/forum/checkIsFollow', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.isFollow = response.data.obj;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			//添加关注
			addFollow() {
				const that = this;
				let params = new FormData()
				params.append('targetUserId', that.info.userId)
				that.axios.post(config.requestUrl + '/front/forum/addFollow', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast('关注成功')
						that.isFollow = 1;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			//取消关注
			delFollow() {
				const that = this;
				let params = new FormData()
				params.append('targetUserId', that.info.userId)
				that.axios.post(config.requestUrl + '/front/forum/delFollow', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast('取消关注成功')
						that.isFollow = 0;
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			//获取评论
			getCommentListByTargetId() {
				const that = this;
				that.loading = true;
				let params = new FormData()
				params.append('pageNum', this.pageNum)
				params.append('pageSize', this.pageSize)
				params.append('targetId', this.id)
				params.append('targetType', 2)
				that.axios.post(config.requestUrl + '/front/forum/getCommentListByTargetId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.total = response.data.obj.total;
						that.$toast.clear();
						let list = that.list;
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatCommentDate(createTime)
							newList[i].formatLikeTotal = util.formatLikeTotal(newList[i].likeTotal)

							for (let j in newList[i].replyPageInfo.list) {
								let createTime2 = newList[i].replyPageInfo.list[j].createTime.replace(/-/g, "/");
								newList[i].replyPageInfo.list[j].createTime = util.formatCommentDate(createTime2)
								newList[i].replyPageInfo.list[j].formatLikeTotal = util.formatLikeTotal(newList[i]
									.replyPageInfo.list[j].likeTotal)
							}
						}
						list = list.concat(newList);
						if (newList.length < that.pageSize) that.finished = true;
						that.pageNum = that.pageNum + 1;
						that.loading = false
						that.list = list;
					} else {
						that.error = true
						that.$toast.fail(response.data.msg);
					}
					that.loading = false
				}, response => {
					that.error = true
					that.loading = false
					that.$toast.fail('获取失败');
				})
			},
			//获取详情
			getTopicDetails() {
				const that = this;
				let params = new FormData()
				params.append('proposalId', that.id)
				params.append('projectId', that.projectId)
				that.axios.post(config.requestUrl + '/front/forum/getProposalDetails', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.info = response.data.obj;
						that.info.formatDate = util.surplusDay(that.info.endTime.replace(/-/g, "/"))
						that.info.endDate = util.formatDate(that.info.endTime.replace(/-/g, "/"))
						that.info.currentSupportRate = (that.info.currentSupportRate).toFixed(2);
						if (that.$store.state.user.token) {
							that.checkIsFollow();
							that.addReadQuantity();
							that.checkIsSupportProposal();
						}
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('获取失败，请稍后重试');
				})
			},
			// 回复评论消息
			responseReview(index, type, sindex) {
				if (!this.$store.state.user.token) {
					this.showLogin = true;
					return;
				}
				let that = this;
				this.type = type;
				this.msg = '';
				this.subindex = index;
				this.ssubindex = sindex;
				if (type == 2) {
					let toUserName = that.list[index].userName
					that.placeholder = '@' + toUserName;
					this.subId = that.list[index].id
				} else {
					let toUserName = that.list[index].replyPageInfo.list[sindex].userName
					that.placeholder = '@' + toUserName;
					this.subId = that.list[index].replyPageInfo.list[sindex].id
				}
				this.showInput = 1;
				this.$refs.mytextarea.focus();
			},
			// 给评论点赞
			praiseReview(index, pindex, type) {
				const that = this;
				let params = new FormData();
				let status = '';
				if (type == 1) {
					status = that.list[index].isLike;
				} else {
					status = that.list[index].replyPageInfo.list[pindex].isLike
				}
				let url = ''
				if (status == 0) {
					url = '/front/forum/addLike';
					if (type == 1) {
						params.append('targetType', 4)
						params.append('targetId', that.list[index].id)
					} else {
						params.append('targetType', 5)
						params.append('targetId', that.list[index].replyPageInfo.list[pindex].id)
					}
				} else {
					url = '/front/forum/delLike'
					if (type == 1) {
						params.append('targetType', 4)
						params.append('targetId', that.list[index].id)
					} else {
						params.append('targetType', 5)
						params.append('targetId', that.list[index].replyPageInfo.list[pindex].id)
					}
				}

				that.axios.post(config.requestUrl + url, params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (status == 0) {
							if (type == 1) {
								that.list[index].isLike = 1;
								that.list[index].likeTotal++;
								that.list[index].formatLikeTotal = util.formatLikeTotal(that.list[index].likeTotal)
							} else {
								that.list[index].replyPageInfo.list[pindex].isLike = 1;
								that.list[index].replyPageInfo.list[pindex].likeTotal++;
								that.list[index].replyPageInfo.list[pindex].formatLikeTotal =
									util.formatLikeTotal(that.list[index].replyPageInfo.list[pindex].likeTotal)
							}
							that.$toast('点赞成功');
						} else {
							if (type == 1) {
								that.list[index].isLike = 0;
								that.list[index].likeTotal--;
								that.list[index].formatLikeTotal = util.formatLikeTotal(that.list[index].likeTotal)
							} else {
								that.list[index].replyPageInfo.list[pindex].isLike = 0;
								that.list[index].replyPageInfo.list[pindex].likeTotal--;
								that.list[index].replyPageInfo.list[pindex].formatLikeTotal =
									util.formatLikeTotal(that.list[index].replyPageInfo.list[pindex].likeTotal)
							}
							that.$toast('取消点赞成功');
						}

					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					that.$toast.fail('点赞失败，请稍后重试');
				})
			},
			// 展开或者收起回复，收起展开是否要考虑分页
			zhankaiResponse(index) {
				const that = this;
				let params = new FormData()
				let length = this.list[index].replyPageInfo.list.length;
				if (length >= this.list[index].replyPageInfo.total) return;
				that.$toast.loading({
					forbidClick: true,
					duration: 0,
				});
				let pageNum = Math.ceil(length / 5) + 1;
				params.append('commentId', this.list[index].id)
				params.append('pageNum', pageNum)
				params.append('pageSize', 5)
				params.append('targetId', this.id)
				params.append('targetType', 2)
				that.axios.post(config.requestUrl + '/front/forum/getReplyListByCommentId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let newList = response.data.obj.list;
						for (let i in newList) {
							let createTime = newList[i].createTime.replace(/-/g, "/");
							newList[i].createTime = util.formatCommentDate(createTime)
							newList[i].formatLikeTotal = util.formatLikeTotal(newList[i].likeTotal)
						}
						let list = that.list[index].replyPageInfo.list.concat(response.data.obj.list);
						that.list[index].replyPageInfo.list = list
					} else {
						that.error = true
						that.$toast.fail(response.data.msg);
					}
					that.loading = false
				}, response => {
					that.error = true
					that.loading = false
					that.$toast.fail('获取失败');
				})
			},
			showReviewInput() {
				if (!this.$store.state.user.token) {
					this.showLogin = true;
					return;
				}
				this.msg = '';
				this.placeholder = '请输入评论';
				this.type = 1;
				this.showInput = 1;
				this.$refs.mytextarea.focus();
			},
			checkMsg(e) {
				if (e.keyCode === 32 || e.keyCode === 13) {
					e.preventDefault();
					return;
				}
			},
			//发送评论
			send() {
				const that = this;
				if (that.msg.trim() == '') {
					that.$toast('请输入内容');
					return;
				}
				that.showInput = false;
				that.$refs.mytextarea.blur();
				that.$toast.loading({
					// message: '...',
					forbidClick: true,
					duration: 0,
				})
				if (that.type == 1) {
					let params = new FormData()
					params.append('content', that.msg)
					params.append('targetId', that.id)
					params.append('targetType', 2)
					that.axios.post(config.requestUrl + '/front/forum/addComment', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							let list = that.list;
							let newList = response.data.obj;
							let createTime = newList.createTime.replace(/-/g, "/");
							newList.createTime = util.formatCommentDate(createTime)
							newList.replyPageInfo = {
								list: [],
								total: 0
							}
							newList.formatLikeTotal = util.formatLikeTotal(newList.likeTotal)
							that.list.unshift(newList);
							that.$toast('发表成功');
						} else {
							that.$toast.fail(response.data.msg);
						}
					}, response => {
						that.$toast.fail('获取失败，请稍后重试');
					})
				} else {
					let params = new FormData()
					params.append('content', that.msg.trim())
					params.append('targetId', that.id)
					params.append('targetType', 2)
					params.append('commentId', that.list[that.subindex].id)
					if (that.type == 3) {
						params.append('replyId', that.list[that.subindex].replyPageInfo.list[that.ssubindex].id)
					}
					that.axios.post(config.requestUrl + '/front/forum/addReply', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							let newList = response.data.obj;
							let createTime = newList.createTime.replace(/-/g, "/");
							newList.createTime = util.formatCommentDate(createTime)

							newList.formatLikeTotal = util.formatLikeTotal(newList.likeTotal)
							// that.list[that.subindex].replyPageInfo.list.unshift(newList);
							that.list[that.subindex].replyPageInfo.list.push(newList);
							that.$toast('发表成功');
						} else {
							that.$toast.fail(response.data.msg);
						}
					}, response => {
						that.$toast.fail('获取失败，请稍后重试');
					})
				}

			}
		},
		mounted() {

			this.id = this.$route.query.id;
			if (!this.id) {
				this.$router.replace('/')
				return;
			}
			this.projectId = this.$route.query.projectId;
			if (!this.projectId) {
				this.$router.replace('/')
				return;
			}
			this.getTopicDetails();

		},
		activated() {

		},
	}
</script>


<style scoped="scoped">
	.zhanwei20 {
		height: 20px;
	}

	.container {
		width: 100%;
		min-height: 100%;
		background: #F5F5F5;
		overflow: visible;
		box-sizing: content-box;
	}

	.creator-sec {
		width: 710px;
		height: 130px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 0 20px 0 20px;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.creator-head {
		width: 70px;
		height: 70px;
		margin-right: 21px;
	}

	.creator-name-sec {}

	.creator-user-name {
		font-size: 26px;
		color: #000000;
		line-height: 26px;
	}

	.creator-time {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		margin-top: 10px;
	}

	.attention-btn {
		width: 120px;
		height: 50px;
		background: #6236FF;
		border-radius: 27px;
		border: 1px solid #6236FF;
		text-align: center;
		font-size: 28px;
		color: #ffffff;
		line-height: 50px;
	}

	.attention-btn-false {
		width: 120px;
		height: 50px;
		background: #ffffff;
		border-radius: 27px;
		border: 1px solid #6236FF;
		text-align: center;
		font-size: 28px;
		color: #6236FF;
		line-height: 50px;
	}

	.detail-sec {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 51px 20px 58px 20px;
		box-sizing: border-box;
		margin: 20px auto 0 auto;
	}

	.detail-title {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.detail-project {
		margin-top: 67px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.detail-desc {
		margin-top: 70px;
		font-size: 24px;
	}

	.review-sec {
		width: 710px;
		background: #FFFFFF;
		border-radius: 10px;
		padding: 20px 30px;
		margin: 20px auto 0 auto;
		box-sizing: border-box;
	}

	.review-all-num {
		font-size: 26px;
		color: #000000;
		line-height: 37px;
		letter-spacing: 1px;
		text-align: center;
	}

	.review-item-sec {
		margin-top: 43px;
	}

	.review-item-head {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.review-item-user {
		/* font-size: 20px; */
		font-size: 22px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.5);
		/* line-height: 20px; */
		line-height: 22px;
	}

	.author-icon {
		width: 62px;
		height: 26px;
		background: #6236FF;
		border-radius: 12px;
		margin-left: 2px;
		text-align: center;
		/* font-size: 18px; */
		font-size: 20px;
		color: #FFFFFF;
		line-height: 26px;
	}

	.review-item-to-icon {
		width: 8px;
		height: 12px;
		margin: 0 10px;
	}

	.review-item-con {
		width: 578px;
		/* font-size: 24px; */

		font-size: 26px;
		color: #000000;
		line-height: 36px;
		margin-bottom: 10px;
		margin-top: 11px;
	}

	.review-item-con2 {
		width: 520px;

		/* font-size: 24px; */
		font-size: 26px;
		color: #000000;
		line-height: 36px;
		margin-bottom: 10px;
		margin-top: 11px;
	}

	.review-item-date {
		/* font-size: 20px; */
		font-size: 22px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 20px;
	}

	.response-btn {
		/* font-size: 20px; */
		font-size: 22px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		margin-left: 40px;
	}

	.review-item-praise-icon {
		width: 20px;
		height: 18px;
	}

	.review-item-praise-num {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 20px;
		margin-left: 10px;
	}

	.review-item-head2 {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		margin-right: 10px;
	}

	.response-list {
		margin-left: 70px;
	}

	.response-item {
		margin-top: 30px;
	}

	.xian {
		width: 578px;
		height: 1px;
		background: #F5F5F5;
		margin-top: 20px;
		margin-bottom: 10px;
		margin-left: 70px;
	}

	.zhankai-sec {
		margin-left: 70px;
	}

	.zhankai-text {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 22px;
	}

	.zhankai-icon {
		width: 11px;
		height: 6px;
		margin-left: 8px;
	}

	.shouqi-icon {
		width: 11px;
		height: 6px;
		margin-left: 8px;
		rotate: 180deg;
	}

	.review-input-sec {
		width: 750px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px -1px 16px 0px rgba(0, 0, 0, 0.1);
		border-radius: 10px 10px 0px 0px;
		position: fixed;
		left: 0;
		bottom: 0;
		padding: 20px;
		box-sizing: border-box;
	}

	.review-input-btn {
		width: 710px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.25);
		padding-left: 30px;
		box-sizing: border-box;
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 60px;
	}

	.height120 {
		height: 120px;
	}

	/* 提案进度部分开始 */
	.progress-sec {
		margin-top: 53px;
	}

	.progress-item {
		padding-top: 20px;
	}

	.progress-text {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
	}

	.progress-line {
		width: 480px;
		height: 20px;
		background: #F5F5F5;
		border-radius: 15px;
		margin-left: 22px;
	}

	.progress-line-finish {
		height: 20px;
		background: linear-gradient(90deg, #FFFFFF 0%, #5A46FF 100%);
		border-radius: 15px;
	}

	.progress-num {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
		margin-right: 11px;
	}

	.progress-line-active {
		width: 480px;
		height: 20px;
		background: #6236FF;
		border-radius: 15px;
		margin-left: 22px;
	}

	.deadline-sec {
		margin-top: 30px;
		width: 660px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 40px;
		border: 1px solid #6236FF;
	}

	.deadline-text {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
		margin-left: 49px;
	}

	.deadline-date {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
		margin-left: 10px;
	}

	.deadline-text2 {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 80px;
		margin-left: 10px;
	}

	.deadline-date2 {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 80px;
		/* margin-left: 10px; */
	}



	.support-btn {
		width: 398px;
		height: 128px;
		position: absolute;
		/* right: -24px; */
		right: -34px;
		top: -8px;
	}

	.support-btn-grey {
		width: 350px;
		height: 80px;
		background: #E6E6E6;
		border-radius: 40px;
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 80px;
		text-align: center;
	}

	.deadline-border-grey {
		border: 1px solid rgba(0, 0, 0, 0.25);
	}

	/* 提案进度部分结束 */

	/* 提案结果弹窗开始 */
	.fail-modal-mask {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
	}

	.fail-modal {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 630px;
		height: 394px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	.fail-tishi-icon {
		position: absolute;
		left: 41px;
		top: -89px;
		width: 140px;
		height: 140px;
	}

	.toupiao-tishi-title {
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 47px;
		margin-top: 39px;
	}

	.toupiao-tishi-con {
		width: 550px;
		height: 130px;
		background: #FFEBDE;
		border-radius: 10px;
		margin: 0 auto;
		font-size: 34px;
		font-family: HuXiaoBo-NanShen, HuXiaoBo;
		color: #D36F2C;
		line-height: 130px;
		text-align: center;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.toupiao-fail-btn-sec {
		margin-top: 60px;
	}

	.toupiao-fail-btn {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 0px 20px;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
		text-align: center;
		border-right: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.toupiao-fail-btn2 {
		width: 315px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 20px 0px;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
		text-align: center;
	}

	.toupiao-succ-btn {
		width: 630px;
		height: 88px;
		background: #FFFFFF;
		box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px -1px 0px 0px rgba(0, 0, 0, 0.1);
		border-radius: 0px 0px 20px 20px;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 88px;
		margin-top: 60px;
		text-align: center;
	}

	.toupiao-tishi-con2 {
		width: 550px;
		height: 130px;
		background: rgba(98, 54, 255, 0.1);
		border-radius: 10px;
		margin: 0 auto;
		font-size: 34px;
		font-family: HuXiaoBo-NanShen, HuXiaoBo;
		color: #3B2F67;
		line-height: 130px;
		text-align: center;
		box-sizing: border-box;
		margin-top: 30px;
	}

	/* 提案结果弹窗结束 */
	.review-input-modal-sec {
		position: fixed;
		left: 0;
		bottom: 0;
	}

	.review-input-modal-sec {
		width: 750px;
		height: 220px;
		background: #FFFFFF;
	}

	.review-input-modal-area {
		width: 590px;
		height: 160px;
		padding: 20px 35px;
		background: #F3F3F3;
		border-radius: 10px;
		box-sizing: border-box;
	}

	.send-btn {
		text-align: center;
		width: 80px;
		height: 60px;
		background: #fc295b;
		color: #ffffff;
		line-height: 60px;
		font-size: 22px;
		border-radius: 26px;
		margin-left: 20px;
	}

	.author-praise-status {
		height: 25px;
		background: rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		text-align: center;
		margin-left: 40px;
		/* font-size: 16px; */
		font-size: 18px;
		color: rgba(0, 0, 0, 0.25);
		line-height: 25px;
		padding: 0 8px;
	}

	.detail-desc>>>img {
		max-width: 100% !important;
	}

	.gf {
		margin-left: 10px;
		padding: 0 5px;
		font-size: 10px;
		color: #FFFFFF;
		background: blue;
		border-radius: 4px;
	}
</style>